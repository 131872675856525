body {
  margin: 0;
  font-family: 'Montserrat';
  line-height: 1.25;
  background: #F9F9F9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 0;
  color: #111111;
}

hr {
  border: 1px solid rgba(229,225,225,255);
}
.content-board{
  display: flex;
  width: 100%;
  overflow: hidden;
}
.wave1-content-board{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}
.settings-header{
  display: flex;
  justify-content: space-between;
  border: 1px solid #DADFF2;
  background: #F9F9F9;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 0px 18px;
}
.settings-header-stage{
  display: flex;
  padding: 8px 12px;
  text-decoration: none;
  color: rgb(105, 109, 120);
  border: 1px solid #D2CFCF;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}
.chosen-header-stage {
  color: #5C9BAB;
  background: rgba(92, 155, 171, 0.1);
  border: 1px solid #5C9BAB;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
}
.settings-page-content{
  display: flex;
  flex-direction: column;
  padding: 54px 68px;
  height: 100%;
  background-color: #FFFFFF;
  gap: 24px;
}
.library-page-content{
  display: flex;
  flex-direction: column;
  padding: 32px 68px;
  height: 100%;
  background-color: #FFFFFF;
  gap: 24px;
}
.settings-page-heading-part{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.settings-page-heading{
  font-size: 22px;
  font-weight: 600;
  color: #5C9BAB;
}
.settings-page-body{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
}
.settings-page-body-tile{
  display: flex;
  padding: 12px 14px;
  border: 1px solid #D2CFCF;
  border-radius: 12px;
  gap: 16px;
  background-color: #F9F9F9;
  cursor: pointer;
}
.chosen-settings-tile{
  border: 1px solid #9A9797;
  background-color: #f4f3f3;
}
.settings-page-body-tile:hover{
  opacity: 0.7;
}
.settings-page-body-tile-icon{
  padding: 12px 0px;
}
.settings-page-body-tile-icon img{
  height: 42px;
  width: 42px;
}
.settings-page-body-tile-text{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.settings-page-body-tile-title{
  display: flex;
  justify-content: space-between;
}
.settings-page-body-tile-name{
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
}
.settings-page-body-tile-status{
  font-size: 13px;
  color: #7A8199;
}
.settings-page-body-tile-status-connected{
  font-size: 13px;
  color: rgb(63, 181, 67);
}
.settings-page-body-tile-description{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #7A8199;
}
.library-page-body{
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 16px;
}
.library-file-tile{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #D2CFCF;
  border-radius: 12px;
  background-color: #F9F9F9;
  cursor: pointer;
  align-items: center;
  padding: 12px 8px;
}
.library-upload-form-tile{
  display: flex;
  border: 1px solid #D2CFCF;
  border-radius: 12px;
  background-color: #F9F9F9;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  width: 100%;
}
.library-file-tile-image img{
  max-height: 64px;
}
.library-file-tile-text{
  padding: 12px 4px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.upload-image-files-list{
  display: grid;
  gap: 12px;
  max-height: 80%;
  padding: 0px 8px;
}
.library-page-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 16px 12px;
}

.library-page-pagination-button {
  border: 1px solid #D2CFCF;
  background-color: #F9F9F9;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.library-page-pagination-button:focus {
  background-color: #e0e0e0;
}
.library-page-pagination-button:disabled {
  background-color: #dddddd; /* Grey background color for disabled buttons */
  cursor: not-allowed;
}

.library-page-pagination-button:hover:not([disabled]) {
  background-color: #e0e0e0; /* Darker green on hover */
}

.library-page-pagination span {
  font-size: 14px;
  font-weight: bold;
}
.library-add-new-file{
  display: flex;
  width: fit-content;
  padding: 12px;
  border: 1px solid #D2CFCF;
  background: #FFFFFF;
  border-radius: 32px;
  cursor: pointer;
}
.library-add-new-file:hover{
  opacity: 0.6;
}
.library-add-new-file img{
  width: 36px;
  height: 36px;
}
.integration-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  height: 30vh;
  justify-content: flex-end;
  padding: 18px;
}
.integration-form-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px; 
  padding: 10px  12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.integration-form-header-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px; 
}
.integration-form-header-description{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px; 
}
.integration-form-button{
  color: #7A8199;
  border: 1px solid #D2CFCF;
  background-color: #F9F9F9;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 16px;  
  width: 140px;
  cursor: pointer;
}
.integration-form-button:hover{
  opacity: 0.7;
}
.iframe-container{
  position: relative;
}
.iframe-menu-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 6px;
  width: 40px;
  height: 40px;
  border-radius: 24px;
  font-size: 16px;
  border: 1px solid #D2CFCF;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: #999999;
  cursor: pointer;
}
.iframe-menu-button:hover{
  border: 1px solid #5c9bab;
  color: #5c9bab;
}
.iframe-menu-container{
  position: absolute;
  display: grid;
  grid-template-rows: 80px 4fr;
  height: 80vh;
  width: 240px;
  border: 1px solid #DADFF2;
  background-color: #FFF;
  right: 10px;
  top: 62px;
  border-radius: 8px;
  z-index: 10;
  gap: 10px;
}
.iframe-menu-container:hover{
  border: 1px SOLID #5c9bab;
}
.iframe-menu-container-color{
  height: 20px;
  width: 20px;
}
.iframe-menu-top-part{
  position: relative;
  height: 60px;
  border-bottom: 1px solid #DADFF2;
  border-radius: 8px 8px 0px 0px;
}
.iframe-menu-user-logo{
  position: absolute;
  top: 8px;
  left: 8px;
}
.iframe-menu-letter{
  position: absolute;
  bottom: -23px;
  left: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  font-size: 22px;
  border: 1px solid #D2CFCF;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.iframe-menu-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 8px 16px;
}
.iframe-menu-container-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.iframe-menu-container-title-name{
  display: flex;
  justify-content: center;
  font-size: 16px;
}
.iframe-menu-container-title-email{
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #D2CFCF;
  overflow-wrap: anywhere;
}
.iframe-menu-footer{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.iframe-menu-footer-option{
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.iframe-menu-footer-option-disabled{
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 8px 12px;
  text-decoration: none;
  color: #000;
  opacity: 0.6;
}
.iframe-menu-footer-option:hover{
  background-color: #f9f9f9;
  color: #5c9bab;
}
.iframe-menu-footer-option-img{
  display: flex;
}
.iframe-menu-footer-option-img img{
  width: 18px;
  height: 18px;
}
.message-holder{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.message{
  max-width: 620px;
  text-align: center;
  color: #7A8199;
  line-height: 1.4;
}

.home{
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
  background: white;
  margin: 30px auto;
  min-width: 20%;
  max-width: 80%;
  border-radius: 10px;
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
}
.home-page-description{
  font-size: 24px;
  color: #5C9BAB;
  line-height: 1.6;
}
.home div{
  padding: 5px;
}
#info{
  font-size: 18px;
  font-weight: 600;
  color: #5C9BAB;
  border-radius: 10px;
}

.board {    
  width: 90%;
  margin-top: 93px;
  margin-left: 34px;
  margin-right: 60px;
  position: relative;
  background-color: white;
  border-radius: 10px;
  border: 2px solid rgba(229,225,225,255);
}
.board-content{
  overflow:auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  z-index: 1;
}

.chooser{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 25px 15px;
}

.list-chooser{
  display: flex;
}
.list-chooser a {
  color:#8D8D8D;
  text-decoration: none;
}

.list-chooser div{
  display: inline-flex;
  align-items: center;
  padding: 5px 12px 6px;
  border-radius: 5px;
}
.list-chooser img{
  filter: opacity(0.7);
}
.list-chooser div :hover{
  filter: opacity(1);
  color:#232323;
  cursor: pointer;
  font-weight: 600;
  background-color: #f0f0f0;
}
#chosen {
  filter: opacity(1);
  color:#232323;
  cursor: pointer;
  font-weight: 600;
  background-color: #f0f0f0;
}
#chosen img{
  filter: opacity(1);
}
.choser-description div{
  padding-bottom: 5px;
}

#tittle{
  font-size: 18px;
  font-weight: 600;
}
#description{
  font-size: 0.9em;
  color: #8D8D8D;
}

.view-choser{
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.auth {
  color:white;
  border-radius: 5px;
  background-color: #5C9BAB;
  display: flex;
  justify-content: center;
  max-width: 6vw;
  cursor: pointer;
  padding: 8px;
}
.auth a {
  color:white;
  text-decoration: none;
}
.auth:hover {
  opacity: 0.7;
}
.design{
  margin: 60px auto;
  min-width: 320px;
  width: 350px;
  border-radius: 10px;
}
.design div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding : 8px;
}
#design-agreement-choser{
  justify-content: space-between;
}
#design-agreement-choser div {
  color:white;
  border-radius: 8px;
  background-color: #5C9BAB;
  padding: 12px 16px ;
}
#design-agreement-choser div:hover{
  cursor: pointer;
  filter: opacity(0.5);
}

.design .drag-and-drop{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background: #F2F2F2;
  border: 1px dashed #D2CFCF;
  box-sizing: border-box;
  border-radius: 8px;
}
.design .drag-and-drop div{
  display: flex;
  align-items: center;
  text-align: center;
  color: #8D8D8D;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
}
.agreements-tiles-container{
  margin: 0px 16px;
}
.agreements-scrollbar__container {
  display: grid;
  height: 100%;
  grid-template: 1fr 16px / auto;
  overflow: hidden;
}
.agreements-scrollbar__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}
.agreements-scrollbar__content::-webkit-scrollbar {
  display: none;
}
.agreements-scrollbar__scrollbar {
  display: grid;
  position: fixed;
  bottom: 8px;
  width: 90%;
}
.agreements-scrollbar__track-and-thumb {
  display: block;
  width: 100%;
  position: relative;
  height: 8px;
}
.agreements-scrollbar__track {
  border-radius: 24px;
  right: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  height: 8px;
}
.agreements-scrollbar__thumb {
  border-radius: 24px;
  background-color: #8a8b99;
  position: absolute;
  height: 8px;
  transition: width .1s ease-in-out;
}
.agreements-tiles{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;  
}
.agreements-list{
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
}
.agreements-list-choser{
  display: flex;
}
.agreements-list-choser-all,
.agreements-list-choser-all-chosen,
.agreements-list-choser-archived,
.agreements-list-choser-archived-chosen{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  font-weight: 600;
  cursor: pointer;
}
.agreements-list-choser-all:hover,
.agreements-list-choser-all-chosen:hover,
.agreements-list-choser-archived:hover,
.agreements-list-choser-archived-chosen:hover{
  opacity: 0.7;
}
.agreements-list-choser-all{
  background: #FFFFFF;
  border: 1px solid #DADFF2; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px 0px 0px 8px;
}
.agreements-list-choser-all-chosen{
  background: rgba(92, 155, 171, 0.1);
  border: 1px solid #5C9BAB;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px 0px 0px 8px;
}
.agreements-list-choser-archived{
  background: #FFFFFF;
  border: 1px solid #DADFF2; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 0px;
}
.agreements-list-choser-archived-chosen{
  background: rgba(92, 155, 171, 0.1);
  border: 1px solid #5C9BAB;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 0px;
}
.agreements-list-body{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 150px;
}
.agreements-list-tittle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.agreements-list-options{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}
.agreements-list-option,
.agreements-list-option-delete{
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 8px 16px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.agreements-list-option-delete{
  color: #CB5151;
}
.agreements-list-option:hover,
.agreements-list-option-delete:hover{
  opacity: 0.6;
}
.agreements-list-table{
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.agreements-list-table td, 
.agreements-list-table th{
  border: 1px solid #DADFF2;
}
.agreement-list-row-div{
  display: flex;
  align-content: center;
}
.agreements-list-table td:first-child,
.agreements-list-table tr:first-child,
.agreements-list-table th:first-child {
  border-top-left-radius: 8px;
}
.agreements-list-table thead {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.agreements-list-table th{
  font-size: 12px;
  color: #7A8199;
}
.agreements-list-table td, 
.agreements-list-table th{
  padding: 12px;
}
.agreements-list-selector{
  width: 40px;
}
.agreements-list-row-selected{
  background: rgba(92, 155, 171, 0.1);
}
.agreements-list-selected{
  display: flex;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #5C9BAB;
  border: 1px solid #5C9BAB;
  border-radius: 4px;
  cursor: pointer;
}
.agreements-list-unselected{
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #7A8199;
  border-radius: 4px;
  cursor: pointer;
}
.agreements-list-selected:hover,
.agreements-list-unselected:hover{
  opacity: 0.7;
}
.agreement-list-droplist-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 5px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  position: absolute;
  box-sizing: border-box;
  z-index: 3;
  left: -244px;
  top: 24px;
}
.agreement-list-three-dots{
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 24px;
}
.agreement-list-three-dots:hover{
  background: #DADFF2;
}
.agreements-graph-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 80px 24px;
}
.agreements-graph{
  position: relative;
  width: 70vw;  
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: flex-start;
  padding: 0px;
}
.agreements-graph-statuses{
  position: relative;
  display: grid;
  grid-template-rows : repeat(8, 1fr);
  flex-direction: row;
  padding: 42px 0px 0px;
  gap: 39px;  
}
.agreements-graph-status{
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  color: #7A8199;
}
.agreements-graph-lines{
  position: relative;
  display: grid;
  grid-template-rows : repeat(8, 1fr);
  flex-direction: row;
  align-items: flex-start;
  padding: 50px 0px 0px;
  gap: 56px;  
}
.agreements-graph-status-column{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0px;
  left: -24px;
  gap: 12px;
  height: 100%;
}
.agreements-graph-status-label{
  left: -10px;
  color: #7A8199;
  font-weight: 600;
}
.agreements-graph-status-line{
  height: 425px;
  width: 1px;
  border: 1px solid #DADFF2;
}
.agreements-graph-row{
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
}
.agreements-graph-row-line{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1px;
  border: 1px solid #DADFF2;
  position: relative;
}
.agreements-graph-row-item-container{
  position: relative;
  width: 12px;
  height: 12px;
  top: -6px;
}
.agreements-graph-row-item{
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 18px;
  z-index: 10;
  cursor: pointer;
}
.agreements-graph-row-item-chosen,
.agreements-graph-row-item:hover {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 18px;
  border: 4px solid #FFFFFF;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  z-index: 10;
  cursor: pointer;
  left:-4px;
  top:-4px;
}
#renewed-item{
  background: #4C9AE1;
}
#signed-item{
  background: #7FD059;
}
#pending-item{
  background: #7FD059;
}
#shared-item{
  background: #FED86F;
}
#inprogress-item{
  background: #FED86F;
}
#alerts-item{
  background: #EE734C;
}
#incomplete-item{
  background: #F89C69;
}
.agreement-item-description{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #FFFFFF;
  width: 16vw;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #5C9BAB;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  margin: 12px 20px;
  z-index: 15;
}
.agreements-graph-date-label{
  position: absolute;
  color: #7A8199;
  font-weight: 600;
  right: -110px;
  top: -9px;
}
.agreements-graph-dates{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  position: absolute;
  width: 100%;
  height: 15px;
  bottom: -24px;
  left: 56px;
}
.agreements-graph-date{
  width: 57px;
  font-size: 12px;
  color: #7A8199;
}
.list-column {
  height: fit-content;
  padding-top: 32px;
  width: 18vw;
}
.list-header{
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
}
.list-header-pointer{
  border-radius: 0px 0px 20px 20px;
  padding: 5px; 
  width: 16px;
  height: 40px; 
}
#alerts-sign {
  background: #EE734C;
}
#incomplete-sign {
  background: #F89C69;
}
#in_progress-sign {
  background: #FED86F;
}
#signed-sign {
  background:#7FD059;
}
#renewed-sign {
  background:#4C9AE1;
}
#archived-sign {
  background: #7A8199;
}

.list-name{
  color: #8e8e8e;
  padding-left: 10px;
}

.agreement-list-vr{
  border: 1px solid #DADFF2;
  width: 0px;
  height: 95vh;
  /* position: absolute; */
  margin: 0px;
}
.agreement-plate {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #FFFFFF;
  position: relative;
  width: 16vw;
  padding: 10px;
  border-radius: 8px;
  border : 1px solid rgba(229,225,225,255);
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  margin: 20px;
}
.agreement-name-holder{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.agreement-name{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.agreement-three-dots {
  display: flex;
  padding: 4px;
  cursor: pointer;
  position: relative;
  border-radius: 24px;
}
.agreement-three-dots:hover{
  background: #DADFF2;
}
.agreement-droplist-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 5px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  position: absolute;
  box-sizing: border-box;
  z-index: 3;
  right: -260px;
  top: 24px;
}
.agreement-company{
  width: 24px;
  height: 24px;
  border: 1px solid #DADFF2;
  border-radius: 4px;
}
.agreement-company-name{
  padding-left: 8px;
  color: #7A8199;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
}
.three-dots {
  align-content: space-between;
  position: absolute;
  right: 2px;
  padding: 0px 5px;
  top: 0px;
  float: right;
  cursor: pointer;
  z-index: 2;
}
.plate a {
  text-decoration: none;
  color: #8D8D8D;
}

.plate a div {
  padding: 4px;
}

.dropdown {
  position:  initial;
  background-color: #FCFCFC;
  border-radius: 8px;
  visibility: hidden;
  outline: none;
  opacity: 0;
  z-index: -2;
  max-height: 0;
  transition: opacity 0.1s, z-index 0.1s, max-height 0.5s;
}
.dropdown a div{
  align-items: center;
  display: flex;
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  display: inline-block;
  visibility: visible;
  border: 1px solid #FCFCFC;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  z-index: 3;
  max-height: 100vh;
  transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}
.dropdown-container a  :hover{
  border-radius: 8px;
  background-color: #D2CFCF4D;
}
.plate-content  {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.name{
  color:#232323;
  font-size: 600;
}
#agreement-date{
  color: #7A8199;
  font-size: 10px;
}
.row {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}
.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 4s ease-out;
  top: 30%;
  position: absolute;
  left: 45%;
}
@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}
@-moz-keyframes spin {
  0% { -moz-transform: rotate(360deg); }
  100% { -moz-transform: rotate(0deg); }
}
@-webkit-keyframes spin {
  0% {-webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(0deg); }
}
.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 4s linear 0s infinite normal;
  -moz-animation: 4s linear 0s normal none infinite spin;
  -webkit-animation: spin 4s linear 0s infinite normal;
}
.auth-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./icons/full_majoto_background.svg");
  background-size: 3600px;
  background-position: -1000px;
  background-color: #1A9FAE;
  height: 100vh;
  width: 100vw;
}
.auth-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.auth-content{
  width: 30vw;
}
.auth-form-name{
  font-size: 18px;
  padding: 12px 0px;
  color: #232323;
}
.auth-form-group {
  padding: 12px 0px;
}
.auth-form-group a{
  text-decoration: none;
  font-size: 13px;
}
.reset-code-input {
  display: flex;
  justify-content: center;
}

.code-input {
  width: 36px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 24px !important;
  border: 1px solid #D2CFCF !important;
  border-radius: 4px !important;
  margin: 0px 6px !important;
}
.auth-form-bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 0px;
}
.auth-form-buttons{
  display: flex;
  gap: 18px;
}
.auth-form-icon {
  display: grid;
  place-self: center;
  padding: 6px;
  border: 2px solid #DADFF2;
  border-radius: 18px;
  cursor: pointer;
}
.auth-form-icon input{
  display: none;
}
.auth-form-icon:hover {
  opacity: 0.7;
}
.auth-form-icon img,
.auth-form-icon label{
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.auth-form-variation{
  display: flex;
  color: #7A8199;
}
.auth-form-variation a{
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  padding-left: 4px;
  color: #7A8199;
}
.auth-btn{
  padding: 8px 16px;
  gap: 10px;
  color: #FFFFFF;
  border: none;
  font-weight: 600;
  background: #5C9BAB;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-decoration: none;
}
.auth-btn:hover{
  cursor: pointer;
  opacity: 0.8;
}

.document-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 40px;
  min-width: 350px;
  border-radius: 10px;
  position: relative;
  height: 80vh;
}
.palitra_button{
  width: 28px;
  height: 28px;
  margin-left: 5px;
  padding: 7px 7px;
  cursor: pointer;
  border: solid 1px #cccccc;
  border-radius: 24px;
}
.palitra_button:hover {
  opacity: 65%;
}
.close_button{
  display: flex;
  cursor: pointer;
  border-radius: 16px;
  padding: 8px; 
}
.close_button:hover{
  background: #DADFF2;
}
.header-menu-button{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-size: 16px;
  border: 1px solid #D2CFCF;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 42px;
  height: 42px;
}
.header-menu-button:hover{
  border: 1px SOLID #5c9bab;
  color: #5c9bab;
}
.launchpad-header{
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #DADFF2;
  background: #F9F9F9;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 0px 18px;
}
.launchpad-header-stages{
  display: flex;
}
.launchpad-header-options{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.header-vertical-hr{
  border: 1px solid rgb(218, 223, 242);
  width: 0px;
  height: 30px;
  margin: 0px 4px;
}
.editor-header-vertical-hr{
  border: 1px solid rgb(218, 223, 242);
  width: 0px;
  height: 30px;
  margin: 0px 4px;
}
.new-editor-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid #DADFF2;
  background: #F9F9F9;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 0px 0px 0px 12px;
}
.editor-stages{
  display: grid;
  grid-template-columns: 1fr 1fr  1fr 1fr 1fr;
  justify-content: center;
}
.editor-header-options{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding: 0px 12px;
}
.nav-div {
  padding: 23px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  color: #7A8199;
  text-decoration: none;
  cursor: pointer;
}
.selectedNav{
  color: #5C9BAB;
}
.nav-div:hover{
  opacity: 0.7;
}
.editor-stage{
  border-radius: 30px 30px 0px 0px;
  background: #5C9BAB;
  width: 80px;
  height: 6px;
  position: absolute;
  bottom: 0;
}
.editor-options-column{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}
.board-body {
  box-sizing: border-box;
  width: inherit;
  height: fit-content;
  background: #F9F9F9;
}
.short-menu-bones {
  display: grid;
  min-width: 64px;
}
.short-menu {
  display: grid;
  position: fixed;
  height: 100vh;
  min-width: 64px;
  background-color: white;
  border-right: 1px solid #DADFF2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 3;
}
.menu-open-bones {
  display: grid;
  min-width: 250px;
}
.menu-open {
  display: grid;
  position: fixed;
  min-height: 100vh;
  min-width: 250px;
  background-color: white;
  border-right: 1px solid #DADFF2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 3;
}
#sidebar-sign {
  border-radius: 0px 30px 30px 0px;
  background: #5C9BAB; 
  width: 6px;
  height: 32px; 
  position: absolute;
  left: 0;
}
.short-menu-sidebar{
  padding: 40px 22px 58px;
  display: flex;
  cursor: pointer;
}
.menu-sidebar-open{
  padding: 26px 24px 51px;
  display: flex;
  cursor: pointer;
}
.menu-active-documents{
  position: relative;
  font-size: 12px;
  color: rgb(122, 129, 153);
  display: flex;
  font-weight: 600;
  align-items: center;
  padding: 0px 24px 8px;
}
.menu-images,
.menu-images-open{
  position: relative;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  height: 42px;
}
.menu-images img,
.menu-images-open img{
  filter: invert(51%) sepia(15%) saturate(434%) hue-rotate(189deg) brightness(96%) contrast(96%);
  height: 16px;
  width: 16px;
}
.menu-images a,
.menu-images-open a{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #7A8199;
  gap: 16px;
}
.menu-bottom{
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 6px;
}
.menu-open-bottom{
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  gap: 6px;
  color: #7A8199;
}
.menu-bottom-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
  height: 35px;
}
.menu-bottom-option a{
  display: flex;
  gap: 12px;
  align-items: center;
  color: #7A8199;
}
.menu-bottom-option:hover{
  opacity: 0.8;
}
#chosen-page{
  filter: invert(61%) sepia(8%) 
  saturate(2024%) hue-rotate(145deg) 
  brightness(93%) contrast(84%);
}

.launch-div {
  padding: 19px 12px;
}

.launch-link {
  display: flex;
  padding: 8px 12px;
  text-decoration: none;
  color: #111111;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.launch-link:hover,
.launch-link-s:hover{
  border: 1px SOLID #5c9bab;
  color: #5c9bab;
}
.launch-link-s {
  display: flex;
  padding: 8px 12px;
  text-decoration: none;
  color: #5C9BAB;
  background: rgba(92, 155, 171, 0.1);
  border: 1px solid #5C9BAB;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.continue div ,
.continue a ,
.create-new  {
  display: flex;
  padding: 8px 16px;
  color: #FFFFFF;
  background: #5C9BAB;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border: 1px SOLID #5C9BAB;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}
.continue:hover,
.create-new:hover{
  border: 1px SOLID #fcfcfc;
}
.documents-board-loading-container{
  position: relative;
  height: 180px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 32px;
  padding: 24px 12vw;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.create-template-label {
  display:inline-flex;
  align-items: center;
  color:#8D8D8D;
  margin: 24px 0;
}
#red-label,
#blue-label{
  width: 24px;
  height: 16px;
  border-radius: 0px 20px 20px 0px;
  margin-right: 8px;
}
#blue-label{
  background: #4C9AE1;
}
#red-label{
  background: #D56858;
}
.create-template{
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 44px;
  padding: 24px 12vw ;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.create-template-description{
  text-align: center;
  padding-bottom: 12px;
  line-height: 140%;
  color: rgb(122, 129, 153);
  max-width: 620px;
}
.actions-droplist-header{
  display: flex;
  align-items: center;
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}
.actions-droplist-header:hover{
  border: 1px SOLID #5c9bab;
  color: #5c9bab;
}
.actions-droplist-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 4px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 3;
  margin-top: 8px;
}
.actions-droplist-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 16px;
  cursor: pointer;
  width: initial;
}
.actions-droplist-option img,
.view-droplist-option img,
.view-droplist-option-selected img{
  padding-right: 10px;
  filter: invert(51%) sepia(15%) saturate(434%) hue-rotate(189deg) brightness(96%) contrast(96%);
}
.actions-droplist-option:hover,
.view-droplist-option:hover{
  color: #5C9BAB;
  background: #F9F9F9;
}
.view-droplist-option-selected{
  color: #5C9BAB;
}
.actions-droplist-option:hover img,
.view-droplist-option:hover img,
.view-droplist-option-selected img{
  filter: invert(61%) sepia(10%) saturate(1373%) hue-rotate(145deg) brightness(91%) contrast(93%);
}

.small-button-list,
.small-button-in,
.small-button-out{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  cursor: pointer;
}
.small-button-list:hover,
.small-button-in:hover,
.small-button-out:hover{
  border: 1px SOLID #5c9bab;
  color: #5c9bab;
}
.small-button-list{
  border-radius: 8px;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
}
.view-droplist{
  position: relative;
}
.view-droplist-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 4px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 5;
  margin-top: 4px;
  right: 0;
}
.view-droplist-option,
.view-droplist-option-selected{
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  cursor: pointer;
}
.small-button-out{
  border-radius: 0px 8px 8px 0px;
}
.small-button-in{
  border-radius: 8px 0px 0px 8px;
}
.templates-list{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 44px;
  grid-row-gap: 16px; 
  padding: 0px 44px;
}
.template{
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.template-plate{
  display: flex;
  flex-direction: column;
}
.template-plate-content{
  padding: 8px 12px;
}
.template-plate-name{
  line-height: 22px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.template-plate-date{
  font-size: 10px;
  line-height: 12px;
  color: rgb(122, 129, 153);
  padding-top: 4px;
  padding-bottom: 40px;
}
.template-droplist-header{
  position: relative;
  cursor: pointer;
}
.template-droplist-header img{
  height: 20px;
}
.template-droplist-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 5px 0px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  position: absolute;
  box-sizing: border-box;
  z-index: 5;
  right: -50px;
  top: 15px;
}
.template-droplist-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 8px 12px 8px 16px;
  font-weight: 600;
  text-decoration: none;
}
.template-droplist-option img{
  padding-right: 9px;
}
.template-droplist-option div a{
  text-decoration: none;
  color: #111;
}
.template-droplist-option img{
  padding-right: 10px;
  filter: invert(51%) sepia(15%) saturate(434%) hue-rotate(189deg) brightness(96%) contrast(96%);
}
.template-droplist-option:hover{
  color: #5C9BAB;
  background: #F9F9F9;
}
.template-droplist-option:hover img{
  filter: invert(61%) sepia(10%) saturate(1373%) hue-rotate(145deg) brightness(91%) contrast(93%);
}
.map-buttons-container{
  display: flex;
  flex-direction: column;
  gap: 12px;
  position:absolute;
  top: 0;
  left: 20px;
}
.map-button-add{
  width:12vw;
  padding: 8px;
  background: #FEF7F8;
  text-align: center;
  border: 1px solid rgb(229 231 235);
  border-radius: 8px;
  cursor: pointer
}
.document-editor{
  display: grid;
  grid-template-columns: 4fr 2fr;
  margin: 32px 24px;
  position: relative;
  gap: 32px;
}
.document-editor-body{
  display: flex;
  flex-flow: column nowrap;
  min-height: 200px;
  height: fit-content;
  width: 100%;
  float: left;
  border-radius: 10px;
  gap: 20px;
}
.components-panel-container{
  position: relative;
  width: 30vw;
}
.components-panel,
.components-panel-is-sticky{
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 8px;
  align-items: center;
  width: inherit;
  animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
  position: relative;
  min-height: 400px;
  width: 30vw;
}
.components-panel-is-sticky{
  position: fixed;
  top: 24px;
  margin-right: 32px;
}
.components-panel-headers{
  display: grid;
  grid-auto-flow: column;
  border-radius: 8px;
  height: 65px;
  margin: 0px 2px -4px;
  width: 100%;
}
.components-panel-headers-plus{
  display: flex;
  align-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background: #F9F9F9;
  border: 1px solid #DADFF2;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  padding: 6px;
  cursor: pointer;
  position: absolute;
  right: -13px;
  top: 14px;
}
.components-panel-headers-plus:hover{
  background: #FFFFFF;
}
.custom-scrollbars__container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template: auto / 1fr 16px;
  overflow: hidden;
}
.custom-scrollbars__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  border-radius: 8px;
  padding: 16px 8px;
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}
.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}
.custom-scrollbars__scrollbar {
  display: grid;
  padding: 16px 0px;
}
.custom-scrollbars__track-and-thumb {
  display: block;
  height: 100%;
  position: relative;
  width: 8px;
}
.custom-scrollbars__track {
  background-color: #DADFF2;
  border-radius: 24px;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 8px;
}
.custom-scrollbars__thumb {
  border-radius: 24px;
  background-color: #8a8b99;
  position: absolute;
  width: 8px;
  transition: height .1s ease-in-out;
}
.components-panel-body{
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  width: 100%;
  height: 70vh;
}
.style-maneken{
  display: flex;
  flex-direction: column;
  border: 1px solid #DADFF2; 
  border-radius: 8px;
  padding: 32px;
  width: 40vw;
}
.style-maneken-n{
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
}
.style-maneken-module-name{
  padding: 10px;
  font-size: 18px;
  border: 1px solid #DADFF2; 
  color: #FFFFFF; 
  background: #92B781;
  border-radius: 8px;
  width: fit-content;
}
.style-maneken-section-name{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #DADFF2; 
  color: #FFFFFF; 
  background: #1E8CFA;
  border-radius: 8px;
  width: fit-content;
}
.style-maneken-section{
  margin-left: 16px;
}
.style-maneken-subsection-name{
  padding: 10px;
  font-size: 14px;
  border: 1px solid #DADFF2; 
  color: #FFFFFF; 
  background: #FA8C1E;
  border-radius: 8px;
  width: fit-content;
}
.style-maneken-subsection{
  margin-left: 32px;
}
.style-maneken-area{
  background: #FFFFFF;
  padding: 24px 16px;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  height: 40px;
  margin-top: 8px;
}

.styling-button{
  background: #FFFFFF;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #DADFF2;
  text-align: center;
}
.styling-button:hover{
  opacity: 0.6;
}
.styling-droplist-button{
  background: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 10px;
  border: 2px solid #DADFF2;
  text-align: center;
  position: relative;
}
.color-picker{
  position: absolute;
  margin: 5px;
  z-index: 2;
}
.text-align-buttons{
  display: flex;
  background: #FFFFFF;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px ;
  border: 2px solid #DADFF2;
  text-align: center;
}
.text-align-buttons:hover{
  background: #E4E4E4
}
.style-border-pickers{
  display: flex;
  justify-content: center;
  border-radius: 8px;
  gap: 16px;
  background: #FFFFFF;
  padding: 8px 12px;
  border: 2px solid #DADFF2;
}
.border-size-picker{
  display: flex;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  width: 60px;
  left: -20px;
  margin-top: 6px;
  padding: 0px 10px;
  z-index: 2;
}
.style-border-option{
  display: flex;
  cursor: pointer;
  position: relative;
}
.style-border-option img{
  width: 18px;
  height: 18px;
}
.style-border-color{
  border: 2px solid #DADFF2;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  position: relative;
}
.border-styles-droplist{
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4px 0px;
  width: 70px;
  left: -20px;
  margin-top: 6px;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}

.arrow_box {
  font-size: 12px;
	position: relative;
	background: #DADFF2;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  padding: 4px 8px;
  margin-top: 25px;
  left: -20px;
  position: absolute;
}
.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.arrow_box:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #DADFF2;
	border-width: 4px;
	margin-left: -4px;
}
.arrow_box:before {
	border-color: rgba(218, 223, 242, 0);
	border-bottom-color: #DADFF2;
	border-width: 7px;
	margin-left: -7px;
}
.form-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px; 
  padding: 10px  12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.editing-form-content{
  box-sizing: border-box;
  border-radius: 20px;
  width: 660px;
  height: 450px;
  background-color: white;
  opacity: 0;
  position: relative;
  transition: all 0.2s ;
  border: 1px solid  #DADFF2;
}
.operation-form-content{
  box-sizing: border-box;
  border-radius: 20px;
  min-width: 300px;
  background-color: white;
  opacity: 1;
  transition: all 0.2s ease 0s;
}
.operation-form-buttons{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.operation-form-cancel-button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DADFF2;
  border-radius: 6px;
  color: #111111;
  padding: 0px 12px;
  height: 32px;
  cursor: pointer;
  background-color: #FFFFFF;
}
.operation-form-cancel-button:hover{
  color: #FFFFFF;
  background-color: #e1e5f3;
}
.operation-form-save-button{
  display: inline-flex;
  align-items: center;
  text-align: center;
  border: 1px solid #DADFF2;
  border-radius: 6px;
  color: #1f883d;
  padding: 0px 38px;
  height: 32px;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  background-color: #FFFFFF;
}
.operation-form-save-button:hover{
  color: #FFFFFF;
  background-color: #1f883d;
}
.form-separator{
  border: 1px solid #DADFF2;
  margin: 0px 0px 8px;
}
.form-header-options{
  display: flex;
  gap: 8px;
}
.form-header-option{
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 12px;
  font-size: 16px;
  border-radius: 16px;
  box-shadow: rgb(204, 204, 204) 0px 0px 2px;
  background: #F9F9F9;
  border: 1px solid #DADFF2;
}
.form-header-option-s{
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 12px;
  border-radius: 16px;
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
}
.form-header-option:hover,
.form-header-option-s:hover{
  opacity: 0.6;
}
.form-bottom-button{
  padding: 10px 20px;
  border: 2px solid rgb(218, 223, 242);
  border-radius: 8px;
  background: rgb(252, 224, 228) none repeat scroll 0% 0%;
  font-size: 16px;  
  width: 140px;
  cursor: pointer;
}
.level-component-chosen{
  outline: 2px solid transparent;
}
.level-component-chosen-hover{
  outline: 2px solid #d505b5;
}
.textarea-component-hover{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}
.textarea-component-chosen-hover{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  outline: 2px solid #d505b5;
}
.textarea-component{
  box-sizing: border-box;
  border: none;
  outline: none ;
  border-radius: 8px;
  resize: none;
  word-break: break-all;
  overflow-wrap: break-word;
}
.page-size-form{
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(218, 223, 242);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 22px;
  gap: 18px;
  min-width: 200px;
  max-width: 28vw;
  opacity: 1;
  transition: all 0.2s ease 0s;
}
.size-form-tittle{
  display: flex;
  font-size: 18px;
  justify-content: center;
}
.size-form-body{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.size-form-choser{
  display: flex;
  gap: 24px;
  align-items: center;
}
.size-form-choser-container{
  display: flex;
  align-items: end;
  gap: 4px;
}
.size-form-choser-container input{
  border: 1px solid #EEEEEE;
  height: inherit;
  font-size: 14px;
  text-align: end;
  width: 120px !important;
  height: 2.5rem !important;
  margin: 0 !important;
}
.size-form-preview{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.size-form-preview-tittle{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.size-form-preview-page{
  border: 2px solid #DADFF2;
  border-radius: 8px;
}
.select-tittle{
  font-size: 18px;
}
.select-options{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
}
.level-options{
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
}
.select-option{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 18px;
  gap: 8px;
  justify-content: space-between;
}
.select-option-spinner{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 70px;
  margin-left: -38px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.select-option-tittle{
  font-weight: 600;
  color: #5C9BAB;
  text-align: center;
}
.select-option-describe{
  font-weight: 500;
  color: #7A8199;
  font-size: 12px;
  text-align: center;
}
.select-option-describe-s{
  font-weight: 500;
  color: #111111;
  font-size: 12px;
  text-align: center;
}
.select-option-ellipse{
  width: 24px; 
  height: 24px;
  position: relative;
  background: #F9F9F9;
  border: 3px solid #7A8199;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}
.select-option-ellipse:hover,
.select-insert-btn:hover{
  opacity: 0.8;
}
.s{
  border: 3px solid #5C9BAB;
}
.select-option-ellipse-inside{
  position: absolute;
  width: 13px; 
  height: 13px;
  background: #5C9BAB;
  border-radius: 50%;
  cursor: pointer;
  top: 50%;
  left: 50%;
  margin: -6.5px 0px 0px -6.5px;
}
.select-insert-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-weight: 600;
  gap: 10px;
  background: #5C9BAB;
  border: none;
  cursor: pointer;
  color: white;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.select-bottom {  
  position: absolute;
  width: 100%;
  bottom: 28px;
}
.style-editor-components{
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 8px;
  margin-left: 50px;
  align-items: center;
}
.dropdown-headers{
  display: grid;
  grid-auto-flow: column;
  border-radius: 8px;
  height: 65px;
  margin: 0px 2px -4px;
  width: 100%;
}
.dropdown-headers-plus{
  display: flex;
  align-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background: #F9F9F9;
  border: 1px solid #DADFF2;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  padding: 6px;
  cursor: pointer;
  position: absolute;
  right: -13px;
  top: 14px;
}
.dropdown-headers-plus:hover{
  background: #FFFFFF;
}
.dropdown-components-group-plus{
  display: flex;
  align-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  padding: 6px;
  cursor: pointer;
  margin-top: -16px;
}
.dropdown-component-plus{
  display: flex;
  align-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  padding: 6px;
  cursor: pointer !important;
  margin: 10px;
}
.dropdown-component-plus:hover,
.dropdown-components-group-plus:hover{
  background: #F9F9F9;
}
.dropdown-body{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  padding-bottom: 32px;
  background: white;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  width: 100%;
}
.dropdown-body-components-group{
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
}
.components-panel-header,
.components-panel-header-s{
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #DADFF2;
}
.components-panel-header{
  background: #F9F9F9;
  color: #403E3E;
}
.components-panel-header-s,
.components-panel-header:hover{
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.components-panel-header-input{
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  box-shadow: none;
  box-sizing: content-box;  
  overflow: hidden;
  max-height: 16px;
  font-family: 'Montserrat' !important;
}
.components-panel-group-header-input{
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  box-shadow: none;
  box-sizing: content-box;  
  overflow: hidden;
  max-height: 16px;
  font-family: 'Montserrat' !important;
}
.dropdown-components-deleting-button{
  display: flex;
  padding: 4px;
  border:  2px solid #DADFF2;
  border-radius: 16px;
  background: #cf222e;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.dropdown-components-adding-checker{
  display: flex;
  padding: 4px;
  border:  1px solid #DADFF2;
  border-radius: 16px;
  background: #5C9BAB;
  position: absolute;
  right: 4px;
  top: 8px;
}
.dropdown-components-deleting-button:hover,
.dropdown-components-adding-checker:hover{
  opacity: 0.6;
}
.dropdown-architecture-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  gap: 24px;
}
.dropdown-compontent-group-header-input-container{
  display: flex;
  justify-content: center;
  padding: 8px 8px 4px 4px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 90%;
  cursor: pointer;
  position: relative;
}
.dropdown-compontent-group-header-input{
  display: flex;
  justify-content: center;
  padding: 8px 8px 4px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 90%;
  position: relative;
  font-family: 'Montserrat' !important;
  line-height: 1.25 !important;  
}
.dropdown-architecture-header{
  display: flex;
  justify-content: center;
  padding: 8px 8px 4px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 90%;
  cursor: pointer;
  position: relative;
}
.dropdown-architecture-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}
.dropdown-architecture-content div:nth-child(2){
  margin-left: 24px;
}
.dropdown-architecture-content div:nth-child(3){
  margin-left: 48px;
}
.dropdown-architecture-content div:nth-child(4){
  margin-left: 72px;
}
.dropdown-styles-container{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.dropdown-elements-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  gap: 16px;
}
.dropdown-elements-container-component{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  padding: 12px 10px;
  background: #FFFFFF;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 6px 1px;
  border-radius: 12px;
  font-size: 14px;
  opacity: 1;
  min-height: 60px;
}
.dropdown-columns-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgb(255, 255, 255);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 260px;
  gap: 16px;
}
.columns-panel-component-style{
  display: grid;
  border-radius: 12px;
  cursor: grab;
  padding: 12px 10px;
  gap: 8px;
  box-shadow: rgb(204, 204, 204) 0px 0px 2px;
  background: #F9F9F9;
  border: 2px solid #DADFF2;
  height: 60px;
  min-width: 110px;
}
.dynamic-block-panel-component-style{
  display: grid;
  place-items: center;
  border-radius: 6px;
  cursor: grab;
  box-shadow: rgb(204, 204, 204) 0px 0px 2px;
  background: #F9F9F9;
  border: 2px dashed darkslategray;
  height: 40px;
  width: 100%;
}
.dynamic-block-separator-panel-component-style{
  width: 1px;
  height: 100%;
  border: 1px solid darkslategray;
}
.style-element-tittle-module,
.style-element-tittle-level1,
.style-element-tittle-level2,
.style-element-tittle-level3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  padding: 4px 8px;
  color: rgba(17, 17, 17, 0.45);
  z-index: 2;
}
.style-element-tittle-module{
  background: #FEF7F8;
  border: 1px solid #E5E5E5;
  color: #868383;
}
.style-element-tittle-level1{
  background: #FCE0E4;
}
.style-element-tittle-level2{
  background: #FAC9D0;
}
.style-element-tittle-level3{
  background: #F8B2BC;
}
.agreement-map-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45vw;
  cursor: grab;
}
.agreement-map-right-panel{
  background: rgb(255, 255, 255);
  position: absolute;
  border: 1px solid #DADFF2;
  right: 0px;
  width: 300px;
  height: 500px;
  top: 160px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 0px 8px;
}
.agreement-map-right-panel-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px ;
  gap: 63px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.agreement-map-right-panel-header-tittle{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  font-weight: 600;
  gap: 8px;
  padding: 8px ;
}
.agreement-map-right-panel-closer{
  display: flex;
  cursor: pointer;
  border-radius: 16px;
  padding: 8px; 
}
.agreement-map-right-panel-closer:hover{
  background: #DADFF2;
}
.agreement-map-right-panel-content{
  padding: 16px 0px 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.agreement-map-right-panel-small{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: rgb(255, 255, 255);
  position: absolute;
  right: 0px;
  top: 40%;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px 0px 0px 8px;
}
.agreement-map-right-panel-small::after{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: rgb(255, 255, 255);
  position: absolute;
  right: 0px;
  top: 40%;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px 0px 0px 8px;
}
.agreement-map-right-panel-option{
  display: flex;
  padding: 8px;
}
.agreement-map-right-panel-option:hover{
  border-radius: 8px;
  background: #DADFF2;
  cursor: pointer;
}
.agreement-map-right-panel-hr{
  border: 1px solid rgb(218, 223, 242);
  width: 30px;
  height: 0px;
}
.agreement-map-trashcan{
  background: rgb(255, 255, 255);
  position: absolute;
  padding: 8px;
  border: 2px solid rgb(218, 223, 242);
  right: 0px;
  top: 50%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
}
.agreement-map-module{
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
}
.agreement-map-ver-line{
  border: 1px solid #DADFF2;
  width: 0px;
  height: 70px;
  margin: 0px;
}
.agreement-map-module-name-container{
  width: 100px;
}
.agreement-map-module-name{
  font-family: 'Montserrat';  
  line-height: 1.25;
  color: #111111;
  width: fit-content;
  padding-bottom: 5px;
  border: medium none;
  resize: none;
  overflow: hidden;
  outline: none;
  height: 26px;
  text-align-last: start;
}
.agreement-map-level1-list{
  height: fit-content;
  padding-top:40px;
  position: relative;
}
.agreement-map-level1-list-absolute{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.agreement-map-level-container{
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: grab;
  border-radius: 8px;
  background: transparent;
  gap: 8px;
}
.agreement-map-level-heading{
  display: flex;
  position: relative;
  cursor: grab;
  border-radius: 8px;
  background: transparent;
  height: 32px;
}
.agreement-map-level-name-container{
  min-width: 6vw;
  width: fit-content;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(218, 223, 242);
}
.agreement-map-level1-name{
  padding: 6px 8px 4px;
  width: fit-content;
  max-width: 12vw;
  border: medium none;
  resize: none;
  overflow: auto;
  outline: none;
  height: 32px;
  text-align-last: start;
}
.agreement-map-level1-num{
  display: flex;
  padding: 0px 8px;
  background-color: #FBD6E7;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 32px;
  width: 24px;
}
.agreement-map-level2-list{
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
  gap: 8px;
  margin-left: 16px;
}
.agreement-map-level2-name{
  padding: 6px 8px 4px;
  width: fit-content;
  max-width: 9vw;
  border: medium none;
  resize: none;
  overflow: auto;
  outline: none;
  height: 32px;
  text-align-last: start;
}
.agreement-map-level2-num{
  display: flex;
  padding: 0px 8px;
  background-color: #F4A6C7;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 32px;
  width: 24px;
}
.agreement-map-level3-list{
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
  gap: 8px;
  margin-left: 32px;
}
.agreement-map-level3-name{
  padding: 6px 8px 4px;
  width: fit-content;
  max-width: 8vw;
  border: medium none;
  resize: none;
  overflow: auto;
  outline: none;
  height: 32px;
  text-align-last: start;
}
.agreement-map-level3-num{
  display: flex;
  padding: 0px 8px;
  background-color: #F17DA4;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 32px;
  width: 24px;
}
.module-item{
  display: flex;
  cursor: grab;
  padding: 12px;
  background: rgb(254, 247, 248);
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 6px 1px;
  border-radius: 8px;
  font-weight: 600;
  color: #5C9BAB;
}
.iframe{
  width: 100vw;
  height: 100vh;
  border: none;
}
.account-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px;
  background: white;
  margin: 30px 20px 60px;
  min-width: 40%;
  min-height: 40%;
  border-radius: 10px;
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
}
.account-info{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  gap: 16px;
}
.invitation-container{
  display: flex;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.invitation-form{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.invitation-emails-droplist-body{
  display: flex;
  flex-flow: column;
  padding: 4px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 3;
  position: absolute;
  margin-top: -32px;
}
.invitation-droplist-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 12px 8px 16px;
  cursor: pointer;
  width: initial;
}
.invitation-droplist-option:hover{
  color: #5C9BAB;
  background: #F9F9F9;
}
.style-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 10px 20px;
}
.style-form-preview-container{
  margin: 60px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80px;
  gap: 8px;
}
.design-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 10px 20px;
}
.design-form-preview-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.style-form-options-body{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px 20px;
}
.style-form-preview-text-holder{
  overflow-wrap: break-word;
  overflow: hidden;
}
.fonts-droplist{
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: absolute;
  width: 95%;
  left: 4px;
  margin-top: 4px;
  height: 105px;
  overflow-y: scroll;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}
.fonts-droplist-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
}
.fonts-droplist-option-selected{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  background: #5C9BAB;
}
.fonts-droplist-option:hover{
  color: #5C9BAB;
  background: #F9F9F9;
}
.font-size-droplist{
  display: flex;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  width: 85%;
  left: 10px;
  padding: 0px 10px;
  z-index: 2;
}
.font-styles-droplist{
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4px 0px;
  width: 95%;
  left: 4px;
  margin-top: 4px;
  height: 58px;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}
.column-holder {
  padding-top: 10px;
  padding-bottom: 8px;
}
.image-container{
  border: 2px solid #DADFF2;
  cursor: pointer;
  box-sizing: content-box;
}
.image-holder-container{
  border-radius: 12px;
  border: 1px solid #DADFF2;
  cursor: pointer;
}
.image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 100%;
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.panel-form-content{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 24px;
  width: 65vw;
  height: 80vh;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s ease 0s;
}
.panel-components-header{
  font-size: 18px;
  line-height: 22px;
}
.panel-components-body{
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;
}
.panel-components-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.panel-components-list-categories{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.panel-components-list-category{
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #7A8199;
}
.panel-components-list-category-selected{
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #5C9BAB;
}
.panel-components-body-content{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 16px;
}
.panel-components-body-content-component{
  display: flex;
  justify-content: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 120px;
  width: 240px;
  cursor: pointer;
}
.panel-components-body-content-component-chosen{
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 16px;
  background: #F9F9F9;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 120px;
  width: 240px;
}
.panel-components-body-content-component-options{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}
.panel-components-body-content-component-structure{
  display: grid;
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 60%;
}
.panel-component-insert-button{
  padding: 8px 16px;
  background: #5C9BAB;
  color: #FFFFFF;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.panel-component-customise-button{
  padding: 8px 16px;
  background: #FFFFFF;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.form-page-hover{
  height: 100%;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease 0s;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}
.creation-folderid-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 20px;
}
.creation-folderid-form-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 16px 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.form-spinner-container{
  position: relative;
  height: 180px;
  margin-left: -20px;
}
.form-selectors-container{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}
.creating-form-selector-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 4px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 3;
  top: 0px;
  right: -140px;
  width: 100%;
  max-height: 340px;
  overflow-y: auto;
}
.upload-image-form-content{
  background: #F9F9F9;
  border: 1px solid rgb(218, 223, 242);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  gap: 16px;
  min-width: 320px;
  opacity: 0;
  transition: all 0.2s ease 0s;
}
.majoto-image-library-form-content{
  background: #F9F9F9;
  border: 1px solid rgb(218, 223, 242);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 32px 16px;
  gap: 16px;
  min-width: 420px;
  opacity: 0;
  transition: all 0.2s ease 0s;
  position: relative;
}
.form-go-back-image{
  display: flex;
  position: absolute;
  border: 1px solid inherit;
  left: 16px;
  top: 16px;
  padding: 8px;
  cursor: pointer;
}
.form-go-back-image img{
  width: 22px;
  height: 22px;
}
.form-go-back-image:hover{
  background: #F9F9F9;
  opacity: 0.7;
}
.majoto-image-library-image{
  border: 1px solid rgb(218, 223, 242);
  border-radius: 16px;
  padding: 8px;
  cursor: pointer;
}
.majoto-image-library-image img{
  width: 28px;
  height: 28px;
}
.upload-image-form-header{
  font-size: 18px;
}
.upload-image-form-buttons{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}
.upload-image-form-button{
  display: flex;
  padding: 8px 16px;
  color: #FFFFFF;
  background: #5C9BAB;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}
.disable-button{
  color: #E0E0E0;
  cursor: default;
  opacity: 0.7;
}
.upload-image-form-button:hover{
  opacity: 0.7;
}
.upload-image-form-or{
  color: #7A8199;
}
.upload-image-form-drop-file{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background: #FFFFFF;
  border: 2px dashed #DADFF2;
  border-radius: 8px;
  height: 85px;
  width: 410px;
  cursor: pointer;
}
.styling-panel-container{
  position: relative;
  width: 30vw;
}
.styling-panel,
.styling-panel-is-sticky{ 
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  border-color: #DADFF2;
  border-radius: 8px;
  height: fit-content;
  align-items: center;
  width: inherit;
  animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
}
.styling-panel-is-sticky{
  position: fixed;
  top: 24px;
  margin-right: 32px;
}
.styling-panel{
  position: relative;
}
.styling-panel-image-options{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 12px;
  align-items: center;
}
.styling-panel-image-option{
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #DADFF2;
  cursor: pointer;
}
.styling-panel-image-option-selected{
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #DADFF2;
  background: #F9F9F9;
  cursor: pointer;
}
.styling-panel-image-option:hover{
  opacity: 0.6;
}
.stilyng-panel-header{
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}
.stilyng-panel-header-name{
  padding: 8px;
}
.styling-panel-close-button{
  display: flex;
  cursor: pointer;
  border-radius: 16px;
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 2px;
}
.styling-panel-close-button:hover{
  background: #DADFF2;
}
.styling-panel-header-hr{
  width: 100%;
  margin: 0;
}
.side-panel-mode-choser{
  display: flex;
  justify-content: flex-start;
  padding: 8px 12px;
  width: 100%;
  gap: 12px;
  background: #F9F9F9;
  outline: 1px solid #DADFF2;
  outline-offset: -1px;
}
.side-panel-mode,
.side-panel-mode-chosen{
  border: 1px solid #DADFF2;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  background: #F9F9F9;
}
.side-panel-mode-chosen{
  background: #FFFFFF;
}
.side-panel-mode:hover{
  opacity: 0.7;
}
.side-panel-classes{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  gap: 18px;
}
.classes-option{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 18px;
  gap: 8px;
  justify-content: space-between;
  border: 1px solid #DADFF2;
  border-radius: 12px;
}
.classes-option-tittle{
  font-weight: 600;
  color: #7A8199;
  text-align: center;
}
.classes-option-tittle-chosen{
  font-weight: 600;
  color: #5C9BAB;
  text-align: center;
}
.pdf-settings-panel{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  gap: 18px;
  width: 100%;
}
.pdf-settings-panel-orientation {
  display: grid;
  grid-template-columns: 5fr 2fr;
  padding: 0px 18px;
  width: 100%;
}
.pdf-settings-panel-orientation-picker{
  display: grid;
  place-self: center;
  text-align: center;
  padding: 6px 8px;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  width: 100%;
}
.pdf-settings-panel-orientation-droplist{
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: absolute;
  width: 98%;
  left: 4px;
  margin-top: 32px;
  height: 65px;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}
.pdf-settings-panel-size-droplist{
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: absolute;
  width: 98%;
  left: 4px;
  margin-top: 32px;
  height: fit-content;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}
.pdf-settings-panel-choser-arrows{
  right: 1px;
  top: 4px;
  position:absolute;
}
.styling-panel-development-message{
  display: grid;
  place-items: center;
  padding: 48px 16px;
}
.styling-panel-body{
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 82vh;
}
.styling-panel-scrollbar__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: white;
  border-radius: 8px;
  padding: 16px 8px;
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}
.styling-panel-scrollbar__content::-webkit-scrollbar {
  display: none;
}
.styling-panel-body-option-name{
  padding: 8px 0px;
}
.styling-panel-font{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 0px 18px;
  width: 100%;
  gap: 8px;
}
.styling-panel-font-family-droplist{
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: absolute;
  width: 98%;
  left: 4px;
  margin-top: 4px;
  height: 115px;
  overflow-y: scroll;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}
.styling-panel-font-style-droplist{
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  position: absolute;
  width: 98%;
  left: 4px;
  margin-top: 4px;
  height: 90px;
  overflow-y: scroll;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 2;
}
.styling-panel-font-style-size-row{
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  gap: 70px;
  width: 100%;
}
.styling-panel-font-container{
  position: relative;
  width: 100%;
}
.styling-panel-font-choser{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #EEEEEE;
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
}
.styling-panel-font-choser-arrows{
  display: flex;
  padding: 2px;
  border-radius: 8px;
  background: #FFFFFF;
}
.styling-panel-font-choser-arrows:hover{
  background: #F9F9F9;
}
.styling-panel-font-size-choser input{
  border: 1px solid #EEEEEE;
  height: inherit;
  font-size: 14px;
  margin: 0;
}
.styling-panel-hr{
  width: 100%;
  margin: 0;
}
.styling-panel-font-type-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  background: #EEEEEE;
  border-radius: 8px;
  padding: 2px;
}
.styling-panel-font-type-container div{
  font-weight: 500;
  font-size: 15px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  cursor: pointer;
  padding: 6px 26px;
  border-radius: 8px;
}
.styling-panel-font-type-container hr{
  margin: 0px 4px;
}
.styling-panel-font-type-container div:hover,
.styling-panel-font-style-chosen{
  background: #FFFFFF;
}
.styling-panel-font-icon{
  height: 16px;
}
.styling-panel-font-icon-hr{
  height: 14px;
  width: 0;
  margin: 0;
  border-color: #000;
}
.styling-panel-text-color{
  display: grid;
  grid-template-columns: 5fr 2fr;
  padding: 0px 18px;
  width: 100%;
}
.styling-panel-text-color-picker{
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  cursor: pointer;
  margin: 4px 0px;
  position: relative;
}
.styling-panel-text-color-picker-color{
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid #DADFF2;
  height: 100%;
}
.styling-panel-text-color-picker-icon{
  display: flex;
  justify-content: center;
}
.styling-panel-text-color-picker-form{
  position: absolute;
  top: 28px;
  left: -130px;
  z-index: 2;
}
.styling-panel-text-alignment{
  display: flex;
  padding: 0px 18px;
  justify-content: center;
  width: 100%;
}
.styling-panel-text-alignment-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  background: #EEEEEE;
  border-radius: 8px;
  padding: 2px;
}
.styling-panel-text-alignment-container div{
  display: flex;
  cursor: pointer;
  padding: 6px 26px;
  border-radius: 8px;
}
.styling-panel-text-alignment-container hr{
  margin: 0px 8px;
}
.styling-panel-text-alignment-container div:hover,
.styling-panel-text-alignment-chosen{
  background: #FFFFFF;
}
.styling-panel-text-spacing{
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  gap: 8px;
  padding: 0px 18px;
  width: 100%;
}
.styling-panel-text-spacing-heading{
  display: flex;
  align-items: center;
  padding: 8px 0px;
  gap: 4px;
  width: 100%;
  cursor: pointer;
}
.styling-panel-text-spacing-heading:hover{
  opacity: 0.7;
}
.styling-panel-text-spacing-options{
  display: flex;
  flex-flow: column;
  gap: 8px;
}
.styling-panel-text-spacing-expand-arrow{
  width: 12px;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  -ms-transition: -ms-transform .3s ease-in-out;
  transition: transform .3s ease-in-out; 
}
.styling-panel-text-spacing-input{
  height: 100% !important;
  margin: 0 !important;
 
}
.styling-panel-text-spacing-option-with-slider{
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
}
.styling-panel-text-spacing-option{
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  gap: 70px;  
  padding: 8px 0px;
}
.styling-panel-text-spacing-input-slider{
  display: grid;
  place-items: center;
}
.styling-panel-border-style-choser{
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  gap: 70px;
  padding: 8px 0px;
}
.styling-panel-border-color{
  display: grid;
  grid-template-columns: 5fr 2fr;
  width: 100%;
}
.MuiSlider-thumb{
  color: white;
  border: 3px solid #DADFF2;
  box-shadow: none;
}
.MuiSlider-thumb:hover{
  box-shadow: 0px 0px 0px 8px rgba(98, 119, 141, 0.16);
}
.MuiSlider-rail{
  color: #b8bcca;
  opacity: 1;
}
.MuiSlider-track{
  color: #b8bcca;
  width: inherit;
}
.image-resizable-container{
  box-sizing: content-box;
}
.resizable-container{
  box-sizing: border-box;
}
.actions-panel-body{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.actions-panel-preview{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 29vw;
  height: 140px;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  margin-top: 16px;
}
.actions-panel-row{
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 94%;
}
.actions-panel-design-item-input-container{
  display: flex;
  align-items: center;
  font-size: 14px;
}
.actions-panel-design-item-input{
  height: 100% !important;
  width: 150px !important;
  margin: 0 !important;
  padding: 0px 8px !important;
  font-size: 14px !important;
}
.actions-panel-row-description{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}
.actions-panel-doesnt-save-button{
  display: inline-flex;
  align-items: center;
  text-align: center;
  border: 1px solid #DADFF2;
  border-radius: 6px;
  color: #1f883d;
  padding: 0px 12px;
  height: 32px;
  font-weight: 600;
  cursor: pointer;
}
.actions-panel-save-button:hover{
  color: #FFFFFF;
  background-color: #1f883d;
}
.actions-panel-save-button{
  display: inline-flex;
  align-items: center;
  text-align: center;
  border: 1px solid #DADFF2;
  border-radius: 6px;
  color: #1f883d;
  padding: 0px 12px;
  height: 32px;
  font-weight: 600;
  cursor: pointer;
}
.actions-panel-save-button:hover{
  color: #FFFFFF;
  background-color: #1f883d;
}
.actions-panel-delete-button{
  display: inline-flex;
  align-items: center;
  border: 1px solid #DADFF2;
  border-radius: 6px;
  color: #cf222e;
  padding: 0px 12px;
  height: 32px;
  font-weight: 600;
  cursor: pointer;
}
.actions-panel-delete-button:hover{
  color: #FFFFFF;
  background-color: #cf222e;
}
.resizeable-box {
  position: relative;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  min-height: 15px;
}
.resizeable-box-drop-hover {
  position: relative;
  border: 2px solid blue;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  min-height: 15px;
}
.resizeable-box-chosen {
  position: relative;
  border: 2px solid #d505b5;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  min-height: 15px;
}

.resizer {
  position: absolute;
  background: white;
  border-radius: 8px;
  border: 2px solid #DADFF2;
  z-index: 2;
  box-sizing: border-box;
}
.resizer-l {
  cursor: col-resize;
  height: 24px;
  left: -6px;
  width: 10px;
}
.resizer-t-l {
  cursor: nwse-resize;
  height: 12px;
  top: -6px;
  left: -6px;
  width: 12px;
}
.resizer-t {
  cursor: row-resize;
  height: 10px;
  top: -6px;
  width: 24px;
}
.resizer-t-r {
  cursor: nesw-resize;
  height: 12px;
  top: -6px;
  right: -6px;
  width: 12px;
}
.resizer-r {
  cursor: col-resize;
  height: 24px;
  right: -6px;
  width: 10px;
}
.resizer-b-r {
  cursor: nwse-resize;
  height: 12px;
  bottom: -6px;
  right: -6px;
  width: 12px;
}
.resizer-b {
  cursor: row-resize;
  height: 10px;
  bottom: -6px;
  width: 24px;
}
.resizer-b-l {
  cursor: nesw-resize;
  height: 12px;
  bottom: -6px;
  left: -6px;
  width: 12px;
}
.separator-block{
  position: relative;
  display: grid;
}
.dynamic-block-separator{
  width: 1px;
  height: 100%;
  place-self: center;
}
.dynamic-block-separator-chosen{
  width: 1px;
  border: 0px;
  background: #d505b5;
  height: 100%;
  place-self: center;
}
.dynamic-block-separator-toggle{
  background: white;
  border-radius: 8px;
  border: 2px solid #DADFF2;
  z-index: 2;
  cursor: col-resize;
  height: 24px;
  width: 10px;
  top: 50%;
  position: absolute;
}

.imageCard {
  text-align: center;
}

.imageCard img {
  width: 600px;
}
.crop-container {
  z-index: 20;
  width: 100%;
  height: 100%;
}

.controls {
  position: fixed;
  bottom: 0px;
  height: 80px;
  z-index: 20;
}
.controls-upper-area {
  text-align: center;
}
.slider {
  width: 50%;
}
.button-area {
  text-align: center;
  margin-top: 20px;
}
.crop-button {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid yellow;
  border-radius: 8px;
  font-size: 20px;
}
.header-document-name-input{
  height: 24.5px !important;
  display: flex !important;
  text-align: center !important;
  width: fit-content !important;
  font-weight: 600 !important;
  margin: 0px 0px 6px 0px !important;
  border: 0px !important;
}
.styling-panel-border-radius-row{
  position: absolute;
  right: 10px;
  top: -10px;
  display: flex;
  gap: 8px;
  align-items: baseline;
}
.styling-panel-border-radius-input{
  width: 40px !important;
  height: 20px !important;
  margin: 0px !important;
}
.component-padding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.component {
  position: relative;
  width: 200px;
  height: 200px;
  border: 2px solid #000;
  border-radius: 8px;
}
.inputs {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.component-padding-input{
  width: 40px !important;
  height: 20px !important;
  margin: 0px !important;
}
.component-padding-input.top {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.component-padding-input.right {
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
}
.component-padding-input.bottom {
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.component-padding-input.left {
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
}

/* Styles for lines */
.component-padding-line {
  position: absolute;
  background-color: #000;
}
.component-padding-line.top {
  top: 4px;
  left: 50%;
  width: 1px;
  height: 15px;
  transform: translateX(-50%);
}
.component-padding-line.right {
  top: 50%;
  right: 4px;
  width: 15px;
  height: 1px;
  transform: translateY(-50%);
}
.component-padding-line.bottom {
  bottom: 4px;
  left: 50%;
  width: 1px;
  height: 15px;
  transform: translateX(-50%);
}
.component-padding-line.left {
  top: 50%;
  left: 4px;
  width: 15px;
  height: 1px;
  transform: translateY(-50%);
}

.component-margin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 0px;
}
.component-margin {
  position: relative;
  width: 140px;
  height: 140px;
  border: 2px solid #000;
  border-radius: 8px;
}
.component-margin-input{
  width: 40px !important;
  height: 20px !important;
  margin: 0px !important;
}
.component-margin-input.top {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.component-margin-input.right {
  position: absolute;
  top: 50%;
  right: -62px;
  transform: translateY(-50%);
}
.component-margin-input.bottom {
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
}
.component-margin-input.left {
  position: absolute;
  top: 50%;
  left: -62px;
  transform: translateY(-50%);
}

/* Styles for lines */
.component-margin-line {
  position: absolute;
  background-color: #000;
}
.component-margin-line.top {
  top: -18px;
  left: 50%;
  width: 1px;
  height: 15px;
  transform: translateX(-50%);
}
.component-margin-line.right {
  top: 50%;
  right: -18px;
  width: 15px;
  height: 1px;
  transform: translateY(-50%);
}
.component-margin-line.bottom {
  bottom: -18px;
  left: 50%;
  width: 1px;
  height: 15px;
  transform: translateX(-50%);
}
.component-margin-line.left {
  top: 50%;
  left: -18px;
  width: 15px;
  height: 1px;
  transform: translateY(-50%);
}
.admin-page-header{
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #DADFF2;
  background: #F9F9F9;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 12px 18px;
}
.admin-page-header-stages{
  display: flex;
  gap: 16px;
}
.admin-page-header-options{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.admin-page-state,
.admin-page-state-s{
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin: 4px 0px;
  text-decoration: none;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.admin-page-state{
  color: #111111;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
}
.admin-page-state-s {
  color: #5C9BAB;
  background: rgba(92, 155, 171, 0.1);
  border: 1px solid #5C9BAB;
}
.admin-page-state:hover,
.admin-page-state-s:hover{
  border: 1px SOLID #5c9bab;
  color: #5c9bab;
}

.admin-control-body{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 10px;
  background: white;
  margin: 30px 20px;
  min-width: 40%;
  min-height: 40%;
  border-radius: 10px;
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
  height: 82vh;
}
.admin-register-body{
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  gap: 32px;
  width: 100%;
}
.admin-register-part{
  display: grid;
  grid-template-columns: 7fr 1fr;
  gap: 8px;
}
.admin-register-actions{
  display: flex;
  flex-direction: column;
  margin: 42px 8px;
  border: 1px solid #DADFF2;
  border-radius: 10px;
  height: fit-content;
}
.admin-register-actions-header{
  color: #5C9BAB;
  font-size: 18px;
  padding: 8px;
}
.admin-register-actions-option{
  cursor: pointer;
  border-radius: 10px;
  padding: 8px;
}
.admin-register-actions-option:hover{
  color: #5C9BAB;
  background: rgba(92, 155, 171, 0.1);
}
.admin-register-actions-option-delete{
  cursor: pointer;
  border-radius: 10px;
  padding: 8px;
  color: #cf222e;
}
.admin-register-actions-option-delete:hover{
  color: #FFFFFF;
  background-color: #cf222e;
}
.admin-register-form{
  display: grid;
  gap: 16px;
}
.admin-register-form-name{
  font-size: 18px;
  font-weight: 600;
  color: #5C9BAB;
}
.admin-selector-container{
  position: relative;
  min-width: 130px;
  width: fit-content;
}
.admin-selector-container-editing{
  position: relative;
  min-width: 130px;
  width: fit-content;
  cursor: pointer;
}
.admin-selector-body-editing{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 4px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 3;
  left: -4px;
  min-width: 100%;
  width: fit-content;
}
.admin-selector-user-container{
  position: relative;
  min-width: 220px;
  width: fit-content;
}
.admin-selector-header{
  display: flex;
  align-items: center;
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #DADFF2;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
}
.admin-selector-body{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 4px 0px;
  background: #FFFFFF none repeat scroll 0% 0%;
  border-radius: 8px;
  border: 1px solid #DADFF2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 3;
  margin-top: 8px;
  width: fit-content;
}
.admin-selector-body-option{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 16px;
  cursor: pointer;
  width: 100%;
}

.admin-selector-body-option:hover{
  color: #5C9BAB;
  background: #F9F9F9;
}
/* .admin-control-table{
  width: 90%;
} */
.admin-control-table-header{
  color: #4a9dac;
  border: none;
  border-bottom: 1px solid #4a9dac;
}
.admin-control-table-header tr{
  border-bottom: 1px solid #4a9dac;
}
.admin-control-table-header th{
  padding: 14px 10px 8px;
  white-space: nowrap;
}
.clickable{
  cursor: pointer;
}
.admin-control-table-empty{
  border: none;
  padding: 0px;
  width: 4px;
}
.admin-control-adding-row{
  display: grid;
  place-self: center;
  cursor: pointer;
}
.admin-control-save-button{
  cursor: pointer;
}
.admin-control-save-button:hover{
  opacity: 0.7;
}
.admin-control-form-update-td{
  padding: 0px 4px 0px 0px; 
}
.admin-control-form-update{
  width: 24px;
  border-radius: 10px;
  border: 2px solid #5bf00a;
  cursor: pointer;
}
.admin-control-form-save-td{
  padding: 0px 4px 0px 0px; 
}
.admin-control-form-upload-td{
  display: flex;
  position: relative;
  justify-content: center;
}
.admin-control-form-upload-input{
  position: absolute;
  left: 0px;
  top: 0px;
  visibility: hidden;
  width: 48px;
}
.admin-control-form-upload-logo{
  cursor: pointer;
}
.admin-control-form-save{
  width: 24px;
  border-radius: 10px;
  border: 2px solid #5bf00a;
  cursor: pointer;
}
.admin-control-form-upload{
  width: 32px;
  border-radius: 10px;
  border: 2px solid #4a9dac;
  cursor: pointer;
  padding: 4px;
}
.admin-control-form-close{
  border-radius: 12px;
  border: 2px solid #f00a0a;
  cursor: pointer;
  padding: 6px;
}
.admin-control-form-update:hover,
.admin-control-form-save:hover,
.admin-control-form-upload:hover,
.admin-control-form-close:hover{
  opacity: 0.6;
}
.admin-control-adding-row img{
  width: 20px;
}
.admin-control-space-name-row,
.admin-control-usual-row{
  border: none;
}
.admin-control-usual-row td{
  padding: 14px 10px !important;
}
.admin-control-usual-row-selected{
  background: rgba(92, 155, 171, 0.1);
  border: none;
}
.admin-control-space-name-row:hover,
.admin-control-usual-row:hover{
  background-color: #eaf0f2;
}

.admin-control-space-selector{
  width: 40px;
}
.admin-control-space-selected{
  background: rgba(92, 155, 171, 0.1);
}
.admin-control-space-selected{
  display: flex;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #5C9BAB;
  border: 1px solid #5C9BAB;
  border-radius: 4px;
  cursor: pointer;
}
.admin-control-space-unselected{
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #7A8199;
  border-radius: 4px;
  cursor: pointer;
}
.admin-control-space-name-row tr, 
.admin-control-usual-row tr{
  display: flex;
  justify-content: space-between;
}
.admin-control-form{
  display: flex;
  gap: 24px;
  align-items: center
}
.admin-control-loading{
  height: 120px;
  position: relative;
}
.admin-controll-archive-message{
  display: flex;
  justify-content: center;
  padding-top: 8px;
  width: 100%;
}
.admin-control-form input{
  margin: 0 !important;
  height: 20px !important;
  font-size: 15px !important;
  font-family: 'Montserrat' !important;
}
.admin-control-usual-row input,
.admin-control-usual-row-selected input{
  margin: 0 !important;
  height: 20px !important;
  font-size: 15px !important;
  font-family: 'Montserrat' !important;
}
.admin-control-usual-row-input{
  border: none !important;
}
.admin-control-usual-row-date{
  position: relative;
  cursor: pointer;
}
.admin-control-usual-row-datePicker{
  position: absolute;
  left: 0;
}
.admin-control-usual-row-datePicker input{
  border: none !important;
  cursor: pointer !important;
}
.register-row-status-New{
  color: #D56858;
}
.register-row-status-Inactive{
  color: darkblue;
}
.register-row-status-Invite{
  color: orange;
}
.register-row-status-Active{
  color: darkgreen;
}
.register-row-status-Activated{
  color: darkgreen;
}
.module-separator{
  position: absolute;
  z-index: 10;
  border-top: 2px solid #DADFF2;
  border-bottom: 2px solid #DADFF2;
  height: 40px;
  width: 100%;
  background: #F9F9F9;
}

.customer-duration-form-container{
  position: relative;
}

.customer-duration-form{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 380px;
  padding: 16px 12px;
  border: 2px solid #DADFF2;
  background: #FFF;
  border-radius: 12px;
  left: -120px;
  z-index: 10;
  top: 18px;
}

.duration-form-label{
  font-size: 13px;
  padding: 4px 8px;
  white-space: nowrap;
  cursor: pointer;
}
.duration-form-label-trial,
.duration-form-label-trial-selected{
  font-size: 13px;
  padding: 6px 8px;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #DADFF2;
  border-radius: 8px;
  margin-right: 12px;
}
.duration-form-label-trial-selected{
  border: 1px SOLID #5c9bab;
  background-color: #eaf0f2;
}
.duration-form-label:hover{
  opacity: 0.8;
}

.duration-slider {
  display: flex;
  align-items: center;
}
.duration-slider-range {
  width: 170px !important;
}

.duration-slider input[type="radio"] {
  margin-right: 0.5rem;
}

.duration-slider input[type="range"] {
  margin: 0 1rem;
}
.duration-slider input[type="range"]::-webkit-slider-thumb {
  background-color: #4a9dac;
}
.duration-slider input[type="range"]::-moz-range-thumb {
  background-color: #4a9dac;
}
.duration-slider input[type="range"]::-ms-thumb {
  background-color: #4a9dac;
}

.copy-link-button{
  cursor: pointer;
}
.copy-link-button:hover{
  color: #5c9bab;
}